import React from "react"
import { Container } from "@components"
import { Box, Flex } from "rebass"
import OtherErrors from "./OtherErrors"

const Content = ({ solution, errorToSkip }) => (
  <Container>
    <Box mt={[32, 62]}>
      <Flex flexDirection="column">
        {
          solution.indexOf("<h2>What's Causing This Error</h2>") === -1 && <h2>Solution</h2>
        }
        <div dangerouslySetInnerHTML={{ __html: solution }} />
      </Flex>
      <OtherErrors errorToSkip={errorToSkip} />
    </Box>
  </Container>
)

export default Content
