import React from "react"
import { FooterWithCTA, SEO, Layout, Hero } from "@components"
import Solution from "@components/pages/errors/SingleErrorPage/Solution.js"
import SideCTA from "@components/SideCTA"

const ErrorPage = ({ pageContext: { error, solution, slug } }) => {
  return (
    <>
      <Layout>
        <SEO
          title={`[Solved] ${error}`}
          description={`This is a common error in DynamoDB -- get the solution for ${error}`}
          canonical={`https://dynobase.dev/dynamodb-errors/${slug}/`}
        />
        <Hero title={`Error: ${error}`} includeAuthorCustom/>
        <Solution solution={solution} errorToSkip={error}/>
        <FooterWithCTA />
      </Layout>
      <SideCTA />
    </>
  )
}

export default ErrorPage
