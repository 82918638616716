import React from "react"
import errors from "@constants/errors"
import { Box } from "rebass"
import { InternalLink } from "@fragments"

const OtherErrors = ({errorToSkip}) => {
  //listing all remaining errors below is leading to indexing problems so making it 15 items instead
  const errorsMinusCurrent = errors.filter(e => e.error !== errorToSkip);
  const errorsShuffled = errorsMinusCurrent.sort(() => 0.5 - Math.random());
  const random15Errors = errorsShuffled.slice(0, 15);
  return (
    <Box mt={[32, 62]}>
      <h2>Other Common DynamoDB Errors (with Solutions)</h2>
      <ul>
        {random15Errors
          .filter(e => e.solution && e.solution.length > 1)
          .map(error => (
            <li style={{ marginBottom: 0 }} key={error.error} className="list-item-with-spacing">
              <InternalLink to={`/dynamodb-errors/${error.slug}/`}>
                {error.error}
              </InternalLink>
            </li>
          ))}
      </ul>
    </Box>
  )
}

export default OtherErrors
